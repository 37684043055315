import { json } from "@remix-run/cloudflare";
import { useLoaderData } from "@remix-run/react";
import { client } from "../models/contentful.server";
import ComponentBuilder from "@/components/ComponentBuilder";


export async function loader({request, params, context} : any) {
	return json(await client.getPage("Home"));
  }
export const meta = ({data} : any) => {
	const {seoMetadata} = data;
	return {
		title: seoMetadata?.title || 'Cannoli - Italialainen ravintola | Italian food and wine',
		description: seoMetadata?.description || 'Italialainen ravintola | Italian food and wine',
		"og:image": `${seoMetadata?.ogImage.url}`
	}
}

export default function Homepage() {
	const { blocksCollection } = useLoaderData();
	return (
		<>
			<ComponentBuilder blocks={blocksCollection?.items} />
		</>
	);
}
